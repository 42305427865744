/* Style the custom card with image */
.custom-card {
    background-color: #f27a24 !important;
    border-radius: 8px; /* Smooth corners */
    padding: 2rem; /* Add padding for spacing */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow */
}

/* Style the image inside the custom card */
.custom-card img {
    max-width: 80%; /* Responsive size */
    height: auto;
    border-radius: 5px; /* Rounded image */
    object-fit: cover; /* Maintain image proportions */
}

/* Custom button styling */
.btn-custom {
    background-color: #f27a24 !important;
    border-color: #f27a24 !important;
    color: white;
    width: 100%;
    max-width: 299.5px;
    height: 35px;
    padding: 0;
    margin: 10px 0;
    font-weight: bold; /* Bold text for emphasis */
    transition: background-color 0.3s ease; /* Smooth hover transition */

    /* Hover effect */
    &:hover {
        background-color: #d66a1d !important;
        border-color: #d66a1d !important;
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .btn-custom {
        max-width: 90%;
    }
}

@media (max-width: 576px) {
    .btn-custom {
        max-width: 95%;
    }

    .custom-card {
        padding: 1rem; /* Adjust padding for smaller screens */
    }
}
